import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonRow, IonToolbar } from "@ionic/react"
import { eyeOutline, folderOpenOutline, personOutline, settingsOutline, trendingUpOutline } from "ionicons/icons"
import { useState } from "react"
import { FilesActive, SettingsActive, TrendingActive, UsersActive, VisualizationActive } from "./ActiveAdminPage"
import { useAuthContext } from "../../context/Auth.context"
import { Logout } from "../../icons"

export const AdminContainer = () => {
    const adminButtonsPagesTemplate = [
        {
            id: 0,
            typeActivePage: 'files',
            icon: folderOpenOutline,
            active: true
        },
        {
            id: 1,
            typeActivePage: 'users',
            icon: personOutline,
            active: false
        },
    ]
    const {logout, styleCompany} = useAuthContext()
    const [pageActive, setPageActive] = useState<string>('files')
    const [adminButtonsPages, setAdminButtonsPage] = useState(adminButtonsPagesTemplate)
    const activePage = (value: string, index: any) => {
        setPageActive(value)
        const buttons = [...adminButtonsPages]
        buttons.forEach(b => {
            b.active = false
        })
        buttons[index].active = true
        setAdminButtonsPage(buttons)
    }

    return (
        <IonContent className={`appContent${styleCompany}`}>
            <IonGrid>
                <IonRow>
                    <IonCol size={'auto'}>
                        <div className="admin-content">
                            <IonToolbar>
                                <IonButtons slot="start">
                                    {
                                        adminButtonsPages.map((button, index) => {
                                            return (
                                                <IonButton key={button.id} onClick={() => {activePage(button.typeActivePage, index)}} className={`side-menu-button ${button.active ? 'button-active' : ''}`} shape={'round'} fill={'clear'}>
                                                    <IonIcon slot={'icon-only'} icon={button.icon} />
                                                </IonButton>
                                            )
                                        })
                                    }
                                    {/* <IonButton onClick={() => {activePage('trending')}} className="side-menu-button" shape={'round'} fill={'clear'}>
                                        <IonIcon slot={'icon-only'} icon={trendingUpOutline} />
                                    </IonButton> */}
                                    {/* <IonButton onClick={() => {activePage('files')}} className="side-menu-button" shape={'round'} fill={'clear'}>
                                        <IonIcon slot={'icon-only'} icon={folderOpenOutline} />
                                    </IonButton> */}
                                    {/* <IonButton onClick={() => {activePage('visualization')}} className="side-menu-button" shape={'round'} fill={'clear'}>
                                        <IonIcon slot={'icon-only'} icon={eyeOutline} />
                                    </IonButton>
                                    <IonButton onClick={() => {activePage('setting')}} className="side-menu-button" shape={'round'} fill={'clear'}>
                                        <IonIcon slot={'icon-only'} icon={settingsOutline} />
                                    </IonButton> */}
                                    {/* <IonButton onClick={() => {activePage('users')}} className="side-menu-button" shape={'round'} fill={'clear'}>
                                        <IonIcon slot={'icon-only'} icon={personOutline} />
                                    </IonButton> */}
                                </IonButtons>
                            </IonToolbar>
                            {
                                (pageActive === 'trending') ?
                                    <TrendingActive />
                                    :
                                    (pageActive === 'files') ?
                                    <FilesActive />
                                    :
                                    (pageActive === 'setting') ?
                                    <SettingsActive />
                                    :
                                    (pageActive === 'visualization') ?
                                    <VisualizationActive />
                                    :
                                    (pageActive === 'users') ?
                                    <UsersActive />
                                    :
                                    null
                                
                            }
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}