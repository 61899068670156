export const ImKChatLogo = './assets/images/Imkchat_logo.svg'
export const ImKChatWhiteLogo = './assets/images/Imkchat_logo_white.svg'
export const UploadCloud = './assets/images/upload-cloud.svg'
export const AiLabs = './assets/icon/logo-ailab.png'
export const KEternalIsotype = 'https://imkchat.blob.core.windows.net/k-eternal/page/isotipo_k-eternal.png'
export const KEternalLogo = 'https://imkchat.blob.core.windows.net/k-eternal/page/logo_k-eternal.png'

/* icons */

export const Admin = './assets/icon/admin.svg'
export const Copy = './assets/icon/copy.svg'
/* export const User = './assets/icon/user.svg' */
export const RightSendArrow = './assets/icon/right-send-arrow.svg'
export const Add = '/img/icons/add.svg'
export const Alerts = '/img/icons/alerts.svg'
export const ChangeDetection = '/img/icons/change-detection.svg'
export const Clipboard = '/img/icons/clipboard.svg'
export const Close = '/img/icons/close.svg'
export const ControlDashboard = '/img/icons/control-dashboard.svg'
export const DownArrow = '/img/icons/down-arrow.svg'
export const RightArrow = '/img/icons/right-arrow.svg'
export const EnvironmentImpact = '/img/icons/environment-impact.svg'
export const Error = '/img/icons/error.svg'
export const Failures = '/img/icons/failures.svg'
export const Favorite = '/img/icons/favorite.svg'
export const Fire = '/img/icons/fire.svg'
export const Gift = '/img/icons/gift.svg'
export const GreenCheck = '/img/icons/green-check.svg'
export const HelpCenter = '/img/icons/help-center.svg'
export const Home = '/img/icons/home.svg'
export const Info = '/img/icons/info.svg'
export const KauelLogoTransparent = '/img/icons/kauel_logo_transparente.png'
export const KauelLogo = '/img/icons/kauel_logo.png'
export const Kauel = '/img/icons/kauel.svg'
export const KauelLogoK = '/img/icons/logo-k.svg'
export const Leaks = '/img/icons/leaks.svg'
export const Mail = '/img/icons/mail.svg'
export const Phone = '/img/icons/phone.svg'
export const Reports = '/img/icons/reports.svg'
export const Trash = '/img/icons/trash.svg'
export const Search = '/img/icons/search.svg'
export const See = '/img/icons/see.svg'
export const SeeOff = '/img/icons/see_off.svg'
export const Settings = '/img/icons/settings.svg'
export const Temperature = '/img/icons/temperature.svg'
export const UpArrow = '/img/icons/up-arrow.svg'
export const VegetationManagement = '/img/icons/vegetation-management.svg'

/* K-ETERNALS */

export const Share = '/k-eternal/icons/Share.svg'
export const User = '/k-eternal/icons/User.svg'
export const Sounds = '/k-eternal/icons/Sounds.svg'
export const Message = '/k-eternal/icons/Message.svg'
export const Send = '/k-eternal/icons/Send.svg'
export const Mic = '/k-eternal/icons/Mic.svg'
export const Menu = '/k-eternal/icons/Menu.svg'
export const Documents = '/k-eternal/icons/Documents.svg'
export const Logout = '/k-eternal/icons/Logout.svg'
export const UploadIcon = '/k-eternal/icons/upload-Icon.svg'