import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react"
import { Admin, Documents, Home, ImKChatLogo, KauelLogoTransparent, Logout, Menu, User } from "../../icons"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/Auth.context"
import SelectFileModal from "../../modals/SelectFile.modal"
import AvatarOptionsModal from "../../modals/AvatarOptions.modal"
import { useHistory } from "react-router"
import { createOutline } from "ionicons/icons"

export const NavbarContainer = () => {
    const {logout, isSmartPhone, logo, iconPage, userInvited, styleCompany} = useAuthContext()
    const [navBarExpanded, setNavBarExpanded] = useState(false)
    const [enableText, setEnableText] = useState(false)
    const [openSelectFileModal, setOpenSelectFileModal] = useState(false)
    const [openAvatarOptionModal, setOpenAvatarOptionModal] = useState(false)
    /* const [openSelectAvatarModal, setOpenSelectAvatarModal] = useState(false) */

    const history = useHistory()

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            const navBar = document.getElementById('navBar')
            if (navBar && navBar.contains(e.target)){
              // Clicked in box
            } else{
              if (navBarExpanded) {
                expandMenu()
              }
            }
        });
    })

    useEffect(() => {
        const navBar = document.getElementById('navBar')
        if (navBarExpanded) {
            if (navBar) {
                navBar.style.width = '299px'
                navBar.style.transition = '1s'
                setTimeout(() => {
                    setEnableText(true)
                }, 600);
            }    
        } else {
            if (navBar) {
                setTimeout(() => {
                    setEnableText(false)
                }, 100);
                navBar.style.width = '108px'
                navBar.style.transition = '1s'
            } 
        }
    }, [navBarExpanded])

    useEffect(() => {
        if (isSmartPhone) {
            setEnableText(true)
        }
    }, [isSmartPhone])

    const expandMenu = () => {
        setNavBarExpanded(navBarExpanded ? false : true)
    }

    return (
        <div className="navBar-container" id={'navBar-container'}>
            {
                openSelectFileModal && <SelectFileModal
                    open={openSelectFileModal}
                    handleClose={() => {setOpenSelectFileModal(false)}}
                />
            }
            {openAvatarOptionModal && <AvatarOptionsModal
                open={openAvatarOptionModal}
                handleClose={() => {setOpenAvatarOptionModal(false)}}
            />}
            <button style={{backgroundColor: 'transparent'}} onClick={() => {expandMenu()}}>
                <IonGrid style={{padding: 0, height: 85}}>
                    <IonRow style={{padding: 0}}>
                        {!enableText && <IonCol style={{padding: 0}} size={enableText ? 'auto' : '12'}>
                            <img height={48} src={iconPage}/>
                        </IonCol>}
                        {
                            enableText && 
                            <IonCol style={{padding: 0}} size="auto">
                                <img /* height={48} */ src={logo} alt="" />
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
            </button>
            <div style={{marginTop: 30, marginBottom: 30, textAlign: 'center', maxWidth: 48}}>
                <p style={{fontFamily:'Manrope'}}>Menu</p>
            </div>
            {userInvited && <IonButton fill="clear" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {alert('Demo no permite creación de cuentas.')}}>
                <IonIcon icon={createOutline} style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} /> {enableText && <IonText style={{marginRight: 10}}>Create account</IonText>}
            </IonButton>}
            {!userInvited && <div>
                {/* <IonButton fill="clear" className={`navBar-button`} onClick={() => {history.push('/home')}}>
                    <IonIcon src={Home} style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} /> {enableText && <IonText style={{marginRight: 10}}>Overview</IonText>}
                </IonButton>
                <br /> */}
                <IonButton fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {setOpenSelectFileModal(true)}}>
                    <IonIcon style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Documents} /> {enableText && <IonText style={{marginRight: 10}}>Files</IonText>}
                </IonButton>
                <br />
                <IonButton fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {setOpenAvatarOptionModal(true)}}>
                    <IonIcon style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={User} /> {enableText && <IonText style={{marginRight: 10}}>Avatar</IonText>}
                </IonButton>
                <br />
                <IonButton fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {history.push('/admin')}}>
                    <IonIcon style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Admin} /> {enableText && <IonText style={{marginRight: 10}}>Admin</IonText>}
                </IonButton>
            </div>}
            <div className="navBar-container" style={{position: "absolute", left: 0, bottom: 0}}>
                <IonButton fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {logout()}}>
                    <IonIcon style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Logout} /> {enableText && <IonText style={{marginRight: 10}}>Log out</IonText>}
                </IonButton>
            </div>
        </div>
    )
}