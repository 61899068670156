import { createContext, useContext, useEffect, useState } from "react";
import { MessageFormat } from "../interfaces/Messages.interface";
import { useFilesContext } from "./Files.context";

interface MessagesType {
    messages: MessageFormat[]
    setMessages: React.Dispatch<React.SetStateAction<MessageFormat[]>>
    avatarWeb: any
    setAvatarWeb: React.Dispatch<any>
}

export const MessagesContext = createContext<MessagesType>({} as MessagesType)


export const MessagesProvider = (props: any) => {
    const {fileSelected, updateFileSelected} = useFilesContext()
    const [messages, setMessages] = useState<MessageFormat[]>([])
    const [avatarWeb, setAvatarWeb] = useState<any>(null)

    useEffect(() => {
        if (fileSelected && fileSelected.messages.length > 0) {
            setMessages(fileSelected.messages)
        }
    },[fileSelected])

    useEffect(() => {
        console.log(messages)
        if (messages.length > 0 && fileSelected) {
            const fileSelectedCache = fileSelected
            fileSelectedCache.messages = messages
            /* updateFileSelected(fileSelectedCache) */
        }
    }, [messages])

    const value = {
        messages,
        setMessages,
        avatarWeb,
        setAvatarWeb,
    }

    return (
        <MessagesContext.Provider value={value} >
            {props.children}
        </MessagesContext.Provider>
    )
}

export const useMessagesContext = () => useContext(MessagesContext)