import { createContext, useContext, useEffect, useState } from "react";
import { FilesData } from "../interfaces/Files.interface";
import { useMessagesContext } from "./Messages.context";
import { getAllFiles, getFileByFileID, getFilesByUserId, updateFileById } from "../routes/files.routes";
import { useAuthContext } from "./Auth.context";
import { useHistory } from "react-router";

interface FilesType {
    files: FilesData[]
    setFiles: React.Dispatch<React.SetStateAction<FilesData[]>>
    getFiles: () => void
    setIdFile: React.Dispatch<React.SetStateAction<number>>
    idFile: number
    fileSelected?: FilesData
    setFileSelected: React.Dispatch<React.SetStateAction<FilesData | undefined>>
    updateFileSelected: (fileSelected: FilesData) => Promise<void>
    waitingLoad: boolean
    setWaitingLoad: React.Dispatch<React.SetStateAction<boolean>>
    waitingButtonLoad: boolean
    setWaitWhileMessage: React.Dispatch<React.SetStateAction<boolean>>
    waitWhileMessage: boolean
    totalFilesInProcess: number
    idFileInProcess: string
    setDisabledInput: React.Dispatch<React.SetStateAction<boolean>>
    disabledInput: boolean
    findFileByNumberID: (id: string) => void
}


export const FilesContext = createContext<FilesType>({} as FilesType)

export const FilesProvider = (props: any) => {
    const {userId, isAuth, user, userInvited, setEnableNavbar} = useAuthContext()
    const {messages} = useMessagesContext()
    const [files, setFiles] = useState<FilesData[]>([])
    const [idFile, setIdFile] = useState<number>(0)
    const [fileSelected, setFileSelected] = useState<FilesData>()
    const [waitingLoad, setWaitingLoad] = useState(true)
    const [waitingButtonLoad, setWaitingButtonLoad] = useState(true)
    const [waitWhileMessage, setWaitWhileMessage] = useState(false)
    const [totalFilesInProcess, setTotalFilesInProcess] = useState(0)
    const [idFileInProcess, setIdFileInProcess] = useState('')
    const [disabledInput, setDisabledInput] = useState(true)

    const history = useHistory()

    useEffect(() => {
        console.log('*****************************CANTIDAD DE ELEMENTOS EN REVISIÓN: ', totalFilesInProcess)
        console.log('*****************************ARCHIVO EN REVISIÓN: ', idFileInProcess)
    },[totalFilesInProcess, idFileInProcess])


    /* useEffect(() => {
        console.log(files)
    }, [files]) */

    /* const downloadFiles = async (file: FilesData) => {
        const {database} = await pdfIndexedDb.init()
        const urlPDF = file.url
        const downloadPDF = await fetch(`${urlPDF}`, {method: 'GET'})
        if (downloadPDF.body) {
            const reader = downloadPDF.body.getReader();
            const ele = await reader.read();
            const interval = setInterval(() => {
                if (ele.done) {
                    clearInterval(interval)
                }
            }, 1000)
        }
    } */

    useEffect(() => {
        if (fileSelected) {
            console.log(fileSelected)
            const fileToSend = {
                id: fileSelected.id,
                messages: fileSelected.messages,
                name: fileSelected.name,
                state: fileSelected.state,
                userId: fileSelected.userId,
                wait: fileSelected.wait
            } as FilesData
            /* updateFileSelected(fileToSend) */
            setWaitingLoad(fileToSend.wait)
        }
    }, [fileSelected])

    useEffect(() => {
        if (messages.length > 0 && fileSelected) {
            const fileSelectedCache = fileSelected
            fileSelectedCache.messages = messages
        }
    }, [messages])

    const findFileByNumberID = async (id: string) => {
        const response = await getFileByFileID(id)
        console.log(response)
        if (response.avatar) {
            if (response.avatar.doc) {
                setFileSelected(response.avatar.doc)
            }
        } else {
            setEnableNavbar(false)
            alert('Token no se reconoce.')
            history.replace('/login')
        }
        /* if (response.files && response.files[0]) {
            const file = response.files[0]
            setFileSelected(file)
        } else {
            alert('Token no se reconoce.')
            history.replace('/login')
        } */
    }

    const updateFileSelected = async (fileSelectedData: FilesData) => {
        setWaitingLoad(true)
        try {
            const response = await updateFileById(fileSelectedData, fileSelectedData.userId[0]._id)
            /* console.log(response) */
            if (response) {
                getFiles()
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (isAuth && user) {
            getFiles()
            
        }
    },[isAuth, user])

    useEffect(() => {
        if (userInvited) {
            findFileByNumberID(userInvited.chatId)
            /* files.forEach((file) => {
                if ((file.id).toString() === userInvited.chatId) {
                    console.log(file)
                    setFileSelected(file)
                }
            }) */
        }
    }, [userInvited])

    const getDataPDF = (data: any) => {
        console.log(data)
        /* let messages: any[] = []
        if (data.data.messages) {
            messages = data.data.messages
        }
        const newMessage : MessageFormat = {
            id: messages.length + 1,
            user: '#bot',
            message: `File ${data.data.name} in progress. please wait when the AI finishes`,
            isNew: true,
            removedBy: []
        }
        data.data.messages = [newMessage]
        if (fileSelected) {
            if (fileSelected.id === data.data.id) {
                setFileSelected(data.data)
            }
        } else {
            setFileSelected(data.data)
        } */
        /* getFiles() */
    }

    const getFiles = async () => {
        if (user) {
            if (user.roles && user.roles[0].name==='SuperAdmin') {
                const response = await getAllFiles()
                console.log(response)
                if (response.files) {
                    setFileSelected(response.files[4])
                    if (response.files.length > 0) {
                        let waitCache = false
                        response.files.forEach((file: any) => {
                            if (file.wait) {
                                waitCache = false
                            }
                        })
                        setWaitingButtonLoad(waitCache)
                        setFiles(response.files)
                    } else {
                        setWaitingButtonLoad(false)
                        setFiles([])
                    }
                } else {
                    setWaitingButtonLoad(false)
                    setFiles([])
                }
            } else {
                const response = await getFilesByUserId(userId)
                if (response.files) {
                    if (response.files.length > 0) {
                        let waitCache = false
                        response.files.forEach((file: any) => {
                            if (file.wait) {
                                waitCache = false
                            }
                        })
                        setWaitingButtonLoad(waitCache)
                        setFiles(response.files)
                    } else {
                        setWaitingButtonLoad(false)
                        setFiles([])
                    }
                } else {
                    setWaitingButtonLoad(false)
                    setFiles([])
                }
            }
        }
    }


    const value = {
        files,
        setFiles,
        getFiles,
        setIdFile,
        idFile,
        fileSelected,
        setFileSelected,
        updateFileSelected,
        waitingLoad,
        setWaitingLoad,
        waitingButtonLoad,
        setWaitWhileMessage,
        waitWhileMessage,
        totalFilesInProcess,
        idFileInProcess,
        disabledInput,
        setDisabledInput,
        findFileByNumberID
    }

    return (
        <FilesContext.Provider value={value} >
            
            {props.children}
        </FilesContext.Provider>
    )
}

export const useFilesContext = () => useContext(FilesContext)